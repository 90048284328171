import { MarkerClusterer } from "../vendor/markerclustererplus.min";
import OnScreen from "onscreen";

let map;
let clusters = [];
let infoBoxTimeout = null;
let loadingTimeout = null;
let currentInfoBoxCluster = null;

const generateInfoWindow = (markers) => {
  let offspringCount = 0;
  let parentCount = 0;
  let spermDonorCount = 0;
  let eggDonorCount = 0;
  let otherCount = 0;
  let parentOffspringCount = 0;
  let facilityCount = 0;
  let totalCount = 0;

  markers.forEach((marker) => {
    offspringCount += marker.dsr_counts.offspring;
    parentCount += marker.dsr_counts.parent;
    spermDonorCount += marker.dsr_counts.spermDonor;
    eggDonorCount += marker.dsr_counts.eggDonor;
    otherCount += marker.dsr_counts.other;
    parentOffspringCount += marker.dsr_counts.parentOffspring;
    facilityCount += marker.dsr_counts.facility;
    totalCount += marker.dsr_counts.total;
  });

  const textClasses = "member-type text-left";

  return (
    `<div class="total-members">${totalCount} Members</div>` +
    `<table><tbody>` +
    (spermDonorCount > 0
      ? `<tr><td class="count-icon"><img src="https://dsr-static-images.s3-us-west-2.amazonaws.com/legend-sperm_donor.png" /></td><td class="count">${spermDonorCount}</td><td class="${textClasses}">Sperm Donors</td></tr>`
      : "") +
    (eggDonorCount > 0
      ? `<tr><td class="count-icon"><img src="https://dsr-static-images.s3-us-west-2.amazonaws.com/legend-egg_donor.png" /></td><td class="count">${eggDonorCount}</td><td class="${textClasses}">Egg Donors</td></tr>`
      : "") +
    (parentCount > 0
      ? `<tr><td class="count-icon"><img src="https://dsr-static-images.s3-us-west-2.amazonaws.com/legend-parent.png" /></td><td class="count">${parentCount}</td><td class="${textClasses}">Parents</td></tr>`
      : "") +
    (offspringCount > 0
      ? `<tr><td class="count-icon"><img src="https://dsr-static-images.s3-us-west-2.amazonaws.com/legend-offspring.png" /></td><td class="count">${offspringCount}</td><td class="${textClasses}">Offspring</td></tr>`
      : "") +
    (otherCount > 0
      ? `<tr><td class="count-icon"><img src="https://dsr-static-images.s3-us-west-2.amazonaws.com/legend-other.png" /></td><td class="count">${otherCount}</td><td class="${textClasses}">Other Members</td></tr>`
      : "") +
    (parentOffspringCount > 0
      ? `<tr><td class="count-icon"><img src="https://dsr-static-images.s3-us-west-2.amazonaws.com/legend-child_offspring.png" /></td><td class="count">${parentOffspringCount}</td><td class="${textClasses}">Offspring posted by Parents</td></tr>`
      : "") +
    (facilityCount > 0
      ? `<tr><td class="count-icon"><img src="https://dsr-static-images.s3-us-west-2.amazonaws.com/legend-facility.png" /></td><td class="count">${facilityCount}</td><td class="${textClasses}">Facilities</td></tr>`
      : "") +
    `</tbody></table>`.replace(/\r?\n?/g, "")
  );
};

const initMap = () => {
  map = new google.maps.Map(document.getElementById("map"), {
    center: { lat: 25, lng: 0 },
    zoom: 2.35,
    disableDefaultUI: true,
    scaleControl: true,
    zoomControl: true,
    zoomControlOptions: {
      style: google.maps.ZoomControlStyle.SMALL,
    },
    scrollwheel: false,
    mapTypeControl: false,
    mapTypeId: "terrain",
  });
};

const addClusters = (memberClusters) => {
  const infoWindow = new google.maps.InfoWindow();
  const markers = [];

  memberClusters.forEach((memberCluster) => {
    const totalCount =
      memberCluster.donor_offspring_count +
      memberCluster.parent_count +
      memberCluster.sperm_donor_count +
      memberCluster.egg_donor_count +
      memberCluster.other_count +
      memberCluster.parent_offspring_count +
      memberCluster.facility_count;
    const marker = new google.maps.Marker({
      position: {
        lat: Number(memberCluster.lat),
        lng: Number(memberCluster.lng),
      },
      label: {
        text: totalCount.toString(),
        fontSize: "10px",
        fontWeight: "800",
      },
      title: "",
      dsr_counts: {
        total: totalCount,
        offspring: memberCluster.donor_offspring_count,
        parent: memberCluster.parent_count,
        spermDonor: memberCluster.sperm_donor_count,
        eggDonor: memberCluster.egg_donor_count,
        other: memberCluster.other_count,
        parentOffspring: memberCluster.parent_offspring_count,
        facility: memberCluster.facility_count,
      },
      map: map,
      icon: "images/map/m1.png",
    });

    google.maps.event.addListener(marker, "mouseover", function() {
      infoWindow.close();
      const content = generateInfoWindow(Array(marker));
      infoWindow.setContent(content);
      infoWindow.open(map, marker);
    });

    google.maps.event.addListener(marker, "mouseout", function() {
      infoWindow.close();
    });

    markers.push(marker);
  });

  const markerClusterer = new MarkerClusterer(map, markers, {
    imagePath: "images/map/m",
    gridSize: 30,
    maxZoom: null,
    batchSize: 5000,
    averageCenter: true,
  });

  // Override calculator to display the member counts on the lable instead of marker counts
  // https://github.com/gmaps-marker-clusterer/gmaps-marker-clusterer/blob/c9d448d62b5634db4e76b6b7e3d913703cbed303/src/markerclusterer.js#L458
  markerClusterer.setCalculator((markers, numStyles) => {
    var index = 0;
    index = Math.min(index, numStyles);

    // Sum total members and use this as the displayed count instead of total markers
    // since a single marker can represent multiple members
    const count = markers.reduce(
      (total, marker) => total + marker.dsr_counts.total,
      0
    );
    var dv = count;
    while (dv !== 0) {
      dv = parseInt(dv / 10, 10);
      index++;
    }

    return {
      text: count,
      index: index,
    };
  });

  google.maps.event.addListener(markerClusterer, "clusteringend", hideLoading);
  google.maps.event.addListener(markerClusterer, "clusteringbegin", () => {
    loadingTimeout = setTimeout(displayLoading, 10);
  });

  google.maps.event.addListener(markerClusterer, "mouseover", function(
    cluster
  ) {
    // don't nuke and respawn the same infobox
    if (currentInfoBoxCluster == cluster) {
      return;
    }
    currentInfoBoxCluster = cluster;

    // delay showing an infobox by 100ms to make it less jittery when mousing over a lot of markers
    clearTimeout(infoBoxTimeout);
    infoBoxTimeout = setTimeout(() => {
      const content = generateInfoWindow(cluster.getMarkers());
      infoWindow.setContent(content);
      infoWindow.setPosition(cluster.getCenter());
      infoWindow.open(map);
    }, 100);
    infoWindow.close();
  });

  google.maps.event.addListener(map, "zoom_changed", function() {
    infoWindow.close();
  });

  google.maps.event.addListener(map, "click", () => {
    infoWindow.close();
  });
};

const hideLoading = () => {
  clearTimeout(loadingTimeout);
  document
    .querySelectorAll("#google-map .loading")
    .forEach((el) => el.classList.add("hidden"));
};

const displayLoading = () => {
  document
    .querySelectorAll("#google-map .loading")
    .forEach((el) => el.classList.remove("hidden"));
};

document.addEventListener("turbolinks:load", () => {
  const mapExists = document.getElementById("map");
  const os = new OnScreen();
  let requestedData = false;

  if (mapExists) {
    initMap();
  }

  os.on("enter", "#google-map", () => {
    if (map && !requestedData) {
      requestedData = true;

      fetch("/member_clusters.json")
        .then((res) => res.json())
        .then((clusters) => addClusters(clusters))
        .catch((err) => console.error(err));
    }
  });
});
