/*Workaround to gray out the placeholder of every select and date field and once
 changed set the color to black
*/

document.addEventListener("turbolinks:load", function() {
  var selects = document.querySelectorAll('select');
  var dateFields = document.querySelectorAll("input[type='date']");

  selects.forEach(function(select) {
    if (select[0].value == "" && select.value == "") {
      select.style.setProperty('color', 'rgb(150,164,184)');
    }
    select.addEventListener('change', changeColor);
  });

  dateFields.forEach(function(dateField) {
    if (dateField.value == "") {
      dateField.style.setProperty('color', 'rgb(150,164,184)');
    }
    dateField.addEventListener('change', changeColor);
  });

  function changeColor(event) {
    event.target.style.setProperty('color', 'black', 'important');
  }

  // if user presses enter submit form, if he presses shift + enter
  // add a new line
  $("#search_by_keyword").keypress(function (e) {
    if(e.which == 13 && !e.shiftKey) {
      $(this).closest("form").submit();
      e.preventDefault();
    }
  });


  // Membership handler for admins

  $('.admin#user_membership_plan_id').on('change', function() {
    // If 'Yearly' was select then set the next expires at date
    if ($(this).val() == '2') {
      var today = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      var new_expire_date = today.toISOString().substring(0, 10);
      $("#user_membership_expires_at").val(new_expire_date);
    } else {
      $("#user_membership_expires_at").val("");
    }
  });

});
