const setDropdownHtml = (dropdown, input, searchResults) => {
  dropdown.innerHTML = `<ul></ul>`
  const ul = dropdown.querySelector('ul')

  searchResults.forEach(searchResult => {
    const listItem = document.createElement('li')
    listItem.innerHTML = searchResult.html

    Object.keys(searchResult.data).forEach((key) => {
      listItem.setAttribute(`data-${key}`, searchResult.data[key])
    })

    listItem.classList.add('typeahead-search-result')
    listItem.addEventListener('mousedown', (e) => {
      input.value = e.target.innerText
    })
    ul.appendChild(listItem)
  })
}

const typeahead = element => {
  const searchApi = element.getAttribute('data-search-api')

  // Add wrapper to the input (element)
  const parent = element.parentNode;
  const wrapper = document.createElement('div')
  wrapper.classList.add('typeahead');
  parent.replaceChild(wrapper, element);
  wrapper.appendChild(element);

  // Add dropdown as a sibling to the input
  var dropdown = document.createElement('div')
  dropdown.classList.add('typeahead-dropdown', 'invisible')
  wrapper.appendChild(dropdown)

  // Show the results dropdown when the input has focus and at least one character input
  element.addEventListener('focus', (e) => {
    dropdown.classList.toggle('invisible', e.target.value.length < 1)
  })

  // Close the results dropdown when the input loses focus
  element.addEventListener('focusout', () => {
    dropdown.classList.add('invisible')
  })

  // Search the api and populate the dropdown with the api response
  element.addEventListener('input', (e) => {
    if (dropdown.classList.toggle('invisible', e.target.value.length < 1)) return

    const searchParams = {}
    searchParams[e.target.name] = e.target.value
    const url = new URL(searchApi)
    url.search = (new URLSearchParams(searchParams)).toString()

    fetch(url, { method: 'get' })
    .then(res => {
      return res.json()
    })
    .then(data => {
      setDropdownHtml(dropdown, element, data)
      element.dispatchEvent(new Event('change'));
    })
    .catch(err => {
      console.error(err);
    })
  })
}

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll(`[data-behavior~=typeahead]`).forEach(element => typeahead(element))
})
