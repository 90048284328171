document.addEventListener("turbolinks:load", async () => {
  const calcSize = () =>{
    const styledCards = document.querySelectorAll(".styled_card");
    const width = window.innerWidth
    styledCards.forEach(card =>{
      width < 480 ? card.classList.remove("w-1/4") : card.classList.add("w-1/4")
      console.log('resized')
    })
  }
  calcSize()
  $(window).resize(calcSize);
})
