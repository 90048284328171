import { loadStripe } from "@stripe/stripe-js";

document.addEventListener("turbolinks:load", async () => {
  // const stripe = await loadStripe("pk_test_Qf6kd9TQpS4NM6AAHbOIxAGf");
  const stripe = await loadStripe("pk_live_mY0CB4AzpZvxd9vt7qHImXG3");

  let elements = stripe.elements({
    fonts: [
      {
        cssSrc: "https://rsms.me/inter/inter-ui.css",
      },
    ],
  });

  /**
   * Card Element
   */
  var card = elements.create("card", {
    hidePostalCode: true,
    style: {
      base: {
        color: "#637381", //"#32325D",
        fontWeight: 500,
        fontFamily: "Inter UI, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",

        "::placeholder": {
          color: "#9bacc8",
        },
      },
      invalid: {
        color: "#E25950",
      },
    },
  });

  card.mount("#membership_payment_card");

  /**
   * Create a token or display an error when the form is submitted
   */
  var form = document.getElementById("membership_payment_form");
  form.addEventListener("submit", (event) => {
    event.preventDefault();
    $('.card-submit').prop('disabled', true);
    const data = createDataObject();
    stripe.createToken(card, data).then((result) => {
      if (result.error) {
        $('.card-submit').prop('disabled', false);
        var errorElement = document.getElementById("membership_payment_error");
        errorElement.textContent = result.error.message;
      } else {
        // Send the token to the backend
        stripeTokenHandler(result.token);
      }
    });
  });

  function stripeTokenHandler(token) {
    var form = document.getElementById("membership_payment_form");
    var hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", "stripeToken");
    hiddenInput.setAttribute("value", token.id);
    form.appendChild(hiddenInput);
    form.submit();
  }

  function createDataObject() {
    const data = {};
    const firstName = getInputValueByName("first_name");
    const lastName = getInputValueByName("last_name");
    data["name"] = firstName + lastName;
    data["email"] = getInputValueByName("email");
    data["address_line1"] = getInputValueByName("address_line");
    data["address_city"] = getInputValueByName("address_city");
    data["address_state"] = getSelectValueByName("address_state");
    data["address_country"] = getSelectValueByName("address_country");
    data["address_zip"] = getInputValueByName("address_zip");
    return data;
  }

  function getInputValueByName(name) {
    let input = document.querySelector('input[name="' + name + '"]');
    return input.value;
  }

  function getSelectValueByName(name) {
    return $('select[name="' + name + '"]').val();
  }
});
