const onDonorIdClick = (e) => {
  const li = e.target.nodeName === 'SPAN' ? e.target.parentNode : e.target

  const donorNumber = li.getAttribute('data-donornumber')
  const facilityName = li.getAttribute('data-facilityname')

  document.querySelector('#q_donor_number_cont').value = donorNumber
  document.querySelector('#q_facility_name_eq').value = facilityName
}

document.addEventListener('turbolinks:load', () => {
  const donorNumberInput = document.querySelector('#q_donor_number_cont')
  if (donorNumberInput != null) {
    donorNumberInput.addEventListener('change', () => {
      document.querySelectorAll('.typeahead-search-result').forEach((e) => {
        e.addEventListener('mousedown', onDonorIdClick);
      })
    })
  }
})


document.addEventListener("turbolinks:load", function() {

  //Get the button
  var goToTopButton = $("#goToTop");

  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function() {toggleGoToTopButton();};

  function toggleGoToTopButton() {
    var scroll = document.body.scrollTop || document.documentElement.scrollTop;
    var maxScroll = $('.content').innerHeight() - $('footer').innerHeight() * 2.25;
     if (scroll > 300 && scroll < maxScroll) {
      goToTopButton.addClass('goToTopFixed');
      goToTopButton.css({display: 'block'});
    } else {
      goToTopButton.removeClass('goToTopFixed');
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  $(goToTopButton).on('click', function() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  });

});
