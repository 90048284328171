import Flickity from "flickity-fade";

const intRandom = (max) => Math.floor(Math.random() * Math.floor(max));

const carousel = (element) => {
  let config = {
    imagesLoaded: true,
    percentPosition: false,
    friction: 0.4,
    autoPlay: false,
    fade: false
  };

  element.querySelector(".carousel-cell").classList.add("burns-active");

  if (element.attributes.hasOwnProperty("data-no-dots"))
    config.pageDots = false;

  if (element.dataset.percentPosition) config.percentPosition = true;

  if (element.dataset.interval)
    config.autoPlay = parseInt(element.dataset.interval);

  if (element.attributes.hasOwnProperty("data-fade")) config.fade = true;

  const flickityInstance = new Flickity(element, config);

  // original resize method
  var resize = flickityInstance.resize;
  flickityInstance.resize = function() {
    if ( !this.isActive ) {
      return;
    }
    if(window.innerWidth < 600){
      this.options.draggable = false;
      this.options.wrapAround = false;
      this.options.cellAlign = 'center';
    }else{
      this.options.draggable = true;
      this.options.wrapAround = true;
      this.options.cellAlign = 'left';
    }
    // call original method
    resize.call( this );
  };

  document.addEventListener("turbolinks:before-render", () => {
    flickityInstance.destroy();
  });

  flickityInstance.on("settle", (offset, i) => {
    let carousel = element;

    carousel.querySelectorAll(".burns-active").forEach((el) => {
      el.classList.remove("burns-active");
    });

    carousel
      .querySelectorAll(".carousel-cell")
      [offset].classList.add("burns-active");
  });
};

document.addEventListener("turbolinks:load", () => {
  document
    .querySelectorAll(`[data-behavior~=carousel]`)
    .forEach((element) => carousel(element));
  document
    .querySelectorAll(".flicker-carousel-wrapper")
    .forEach((element) => carousel(element));
  // document
  //   .querySelectorAll(".testimonials-carousel")
  //   .forEach((element) => carousel(element));
});

document.addEventListener("turbolinks:render", () => {
  document
    .querySelectorAll(".flicker-carousel-wrapper")
    .forEach((element) => carousel(element));
});

$(window).on("load", function() {
  document
    .querySelectorAll(".flicker-carousel-wrapper")
    .forEach((element) => carousel(element));
});
