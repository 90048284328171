document.addEventListener("turbolinks:load", async () => {
  function squareHeaderSizing() {
    let images = document.querySelectorAll(".thumbnail > img");
    let maxHeight = 0;
    images.forEach((image) => {
      if (maxHeight < image.height) {
        maxHeight = image.height;
      }
    });
    maxHeight = 200;
    let thumbnails = document.querySelectorAll(".thumbnail");
    for (let i = 0; i < thumbnails.length; i++) {
      $(thumbnails[i]).height(maxHeight);
    }

    // sub header div
    let sub_header_divs = document.querySelectorAll(".sub_header_div");
    let subHeaderMaxHeight = 0;
    sub_header_divs.forEach((subheader) => {
      if (subHeaderMaxHeight < $(subheader)[0].scrollHeight) {
        subHeaderMaxHeight = $(subheader)[0].scrollHeight;
      }
    });
    for (let i = 0; i < sub_header_divs.length; i++) {
      if (
        !$(sub_header_divs[i])
          .find(".subheader_shown")
          .is(":visible")
      ) {
        $(sub_header_divs[i]).height(subHeaderMaxHeight);
      }
    }

    subHeaderMaxHeight = 0;
    sub_header_divs.forEach((subheader) => {
      let totalChildHeight = 0;
      let children = $(subheader).children();
      for (let i = 0; i < children.length; i++) {
        if (!children[i].classList.contains("subheader_shown")) {
          totalChildHeight += $(children[i]).height();
        }
      }
      if (subHeaderMaxHeight < totalChildHeight) {
        subHeaderMaxHeight = totalChildHeight;
      }
    });
    for (let i = 0; i < sub_header_divs.length; i++) {
      if (
        !$(sub_header_divs[i])
          .find(".subheader_shown")
          .is(":visible")
      ) {
        $(sub_header_divs[i]).height(window.innerWidth > '480' ? subHeaderMaxHeight : "auto");
      }
    }
  }
  squareHeaderSizing();
  $(window).resize(squareHeaderSizing);

  const toggleShow = (element) => {
    $(element)
      .find(".subheader_hidden")
      .click(function() {
        $(this).hide();
        $(this)
          .siblings(".subheader_shown")
          .show();
        $(this)
          .parent()
          .height("auto");
      });
    $(element)
      .find(".subheader_shown_carret")
      .click(function() {
        $(this)
          .parent()
          .hide();
        $(this)
          .parent()
          .siblings(".subheader_hidden")
          .show();
        squareHeaderSizing();
      });
  };

  document
    .querySelectorAll(".sub_header_div")
    .forEach((element) => toggleShow(element));
});
