document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll(".member-menu-link").forEach((link) => {
    $(link).click(addOnClickToPaymentSiteListener);
  });
});

const addOnClickToPaymentSiteListener = () => {
  var membership_field = document.getElementById("my_memberships_path")
  var membership_path = membership_field.value

  $(".notifications").append(
    "<div class='notification alert' role='alert'>" +
      "  <div class='notification-text'>" +
      "    <p><strong>You must be a paid member to complete this operation.</strong></p>" +
      "    <p>Membership benefits include:</p>" +
      "    <ul class='my-5'>" +
      "      <li>Connect with your matches and other DSR members, via the DSR’s Message Center</li>" +
      "      <li>Share photos with your matches</li>" +
      "      <li>Share medical/genetic info with your matches</li>" +
      "      <li>Share your success story on the DSR’s Success Stories page</li>" +
      "      <li>Access the DSR’s news and video archives</li>" +
      "      <li>Edit your personal details page and posting(s)</li>" +
      "      <li>Support the DSR’s mission to connect, educate, and support all those in the donor family</li>" +
      "    </ul>" +
      "    <a href=" + membership_path + " class='btn bg-white text-black rounded p-2'>Upgrade Membership</a>" +
      "  </div>" +
      "  <button class='button close' data-behavior='close-notification'>" +
      "    <icon class='fas fa-times' />" +
      "  </button>" +
      "</div>"
  );
  const setCloseHandler = (element) => {
    element.addEventListener("click", (event) => {
      element.closest(".notification").remove();
    });
  };

  setTimeout(() => {
    document.querySelectorAll(".notification").forEach((element) => {
      $(element)
        .closest(".notification")
        .remove();
    });
  }, 10000); // 10s

  document
    .querySelectorAll('[data-behavior="close-notification"]')
    .forEach(setCloseHandler);
};
