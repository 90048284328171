import tabs from "tabs";

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll(`[data-behavior~=tabs]`).forEach(tabs);
  if (document.querySelector("nav a.tab")) {
    document.querySelector("nav a.tab").classList.add("active");
  }
  if (document.querySelector("article.tab-pane")) {
    document.querySelector("article.tab-pane").classList.add("active");
  }
});

// tabs for the dashboard and stats pages
document.addEventListener("turbolinks:load", function() {
  $("#nav-container section").hide();
  $("#nav-container section:first").show();

  var regexp = new RegExp(/(\w+)_page/);
  var pageParam = regexp.exec(window.location.search);

  // When using pagination keep user on current tab
  if (pageParam && pageParam[1]) {
    $("ul#nav li a").removeClass("border-l border-t border-r rounded-t");
    $(`a[data-href="#${pageParam[1]}"]`).addClass("border-l border-t border-r rounded-t");
    $("#nav-container section").hide();
    $(`#${pageParam[1]}`).show();
  }

  // If there are subtabs, always render the content of the first one
  $(".sub-nav-container").each(function(section) {
    $(this).find('section:first').show();
  });

  $("ul#nav li a, ul.sub-nav li a").on("click", function() {
    var currentTab = $(this).data("href");
    var vis = $(currentTab).is(":visible");

    $(this).parent().siblings().find('a').removeClass("border-l border-t border-r rounded-t");
    $(this).addClass("border-l border-t border-r rounded-t");

    // If not visible, hide current section and render content of the one that was clicked
    if (!vis) {
      if ($(this).parents('ul').hasClass('sub-nav')) {
        $(".sub-nav-container section").hide();
      } else {
        // Hide current tab but always keep visible the content of first subtabs
        $("#nav-container section").hide();
        // Mark first subtab as active
        $('ul.sub-nav li a').removeClass("border-l border-t border-r rounded-t");
        $('ul.sub-nav').each(function() {
          $(this).find('li:first a').addClass("border-l border-t border-r rounded-t");
        });
        $(".sub-nav-container section").hide();
        $(".sub-nav-container").each(function(section) {
          $(this).find('section:first').show();
        });
      }
      $(currentTab).show();
    }

    if ($(this).data('url').length > 0 ) {
      $.ajax({ url: $(this).data('url') });
    }
  });

});
