import { CountUp } from 'countup.js';
import OnScreen from 'onscreen';

const counter = (element) => {
  if (element.dataset.counted) return;

  const final_number = element.dataset.countTo
  var countUp = new CountUp(element, final_number);
  countUp.start(() => {
    element.dataset.counted = true
  });
}

document.addEventListener("turbolinks:load", () => {
  var os = new OnScreen()

  os.on('enter', "[data-behavior~=track-scroll]", (element, event) => {
    if (element.dataset.countTo) {
      counter(element)
    }
  })

  document.querySelectorAll("[data-count-to]").forEach((e) => { e.innerHTML = "0" })
})
