const accordion = (element) => {
  $(element)
    .find(".plus")
    .click(function() {
      $(this).hide();
      $(this)
        .siblings(".minus")
        .show();
      $(this)
        .parent()
        .parent()
        .find(".answer")
        .show();
    });
  $(element)
    .find(".minus")
    .click(function() {
      $(this).hide();
      $(this)
        .siblings(".plus")
        .show();
      $(this)
        .parent()
        .parent()
        .find(".answer")
        .hide();
    });
  $(element)
    .find(".minus")
    .hide();
  $(element)
    .find(".answer")
    .hide();
};

document.addEventListener("turbolinks:load", () => {
  // document
  //   .querySelectorAll(".collapse")
  //   .forEach((element) => carousel(element));

  document
    .querySelectorAll(".accordion-item")
    .forEach((element) => accordion(element));
});

// var acc = document.getElementsByClassName("accordion");
// var i;

// for (i = 0; i < acc.length; i++) {
//   acc[i].addEventListener("click", function() {
//     /* Toggle between adding and removing the "active" class,
//     to highlight the button that controls the panel */
//     this.classList.toggle("active");

//     /* Toggle between hiding and showing the active panel */
//     var panel = this.nextElementSibling;
//     if (panel.style.display === "block") {
//       panel.style.display = "none";
//     } else {
//       panel.style.display = "block";
//     }
//   });
// }

// https://www.w3schools.com/howto/howto_js_accordion.asp
