// Adapted from:
// https://github.com/kbaum/browser-timezone-rails/blob/master/app/assets/javascripts/browser_timezone_rails/set_time_zone.js.erb
// (Had to change the ERB syntax to JS)
(function() {
  const Cookies = require('js-cookie');
  Cookies.set(
    "browser.timezone",
    Intl.DateTimeFormat().resolvedOptions().timeZone,
    {
      expires: 365,
      path: '/',
      secure: (location.protocol === "https:"),
    }
  );
})();
