document.addEventListener("turbolinks:load", async () => {

  // Handler for existing offspring
  $(".remove-offspring").click((obj) => {
    obj.stopPropagation();

    $(obj.currentTarget)
      .parents(".offspring_row")
      .addClass('hide');

    $(obj.currentTarget).parents('.offspring_row').find('.destroy').val('true');

    return false;
  });

  function offspring_index() {
    var reg = new RegExp('[0-9]+');
    var text = $('.offspring-index').parent('.offspring_row').not('.hide').last().text();
    if (text.length > 0) {
    var number = reg.exec(text)[0];
      return number * 1 + 1;
    } else {
      return 1;
    }
  }

  function yearDropdownOptions()  {
    var maxYear = (new Date).getFullYear() + 5;
    var dropdownOptions = "";
    for (var i = 1920 ; i <= maxYear ;  i++) {
      dropdownOptions += `<option value=${i}>${i}</option>`;
    }
    return dropdownOptions;
  }

  $("#add-offspring").click((e) => {
    e.stopPropagation();
    var i = Date.now();
    $("#offspring_rows").append(
      "<div class='mb-8 offspring_row remove-offspring-row'>" +
        " <p class='offspring-index text-gray-700'> Offspring #" + offspring_index() + "</p>" +
        "  <div class='field'>" +
        "   <div class='field date-picker'>" +
        "     <label>Birthdate</label>" +
        "     <div class='control flex flex-row'>" +
        "       <div class='flex flex-row'>" +
        "         <select name='post[offspring_attributes]["+i+"][birthdate_month]' style='color: rgb(150, 164, 184);'>" +
        "           <option value>Month</option>" +
        "           <option value='1'>January</option>" +
        "           <option value='2'>Febuary</option>" +
        "           <option value='3'>March</option>" +
        "           <option value='4'>April</option>" +
        "           <option value='5'>May</option>" +
        "           <option value='6'>June</option>" +
        "           <option value='7'>July</option>" +
        "           <option value='8'>August</option>" +
        "           <option value='9'>September</option>" +
        "           <option value='10'>October</option>" +
        "           <option value='11'>November</option>" +
        "           <option value='12'>December</option>" +
        "         </select>" +
        "         <div class='date-sperator'>/</div>" +
        "         <div class='form-select'>" +
        "           <select name='post[offspring_attributes]["+ i +"][birthdate_day]' style='color: rgb(150, 164, 184);'>" +
        "             <option value>Day</option>" +
        "             <option value='1'>1</option>" +
        "             <option value='2'>2</option>" +
        "             <option value='3'>3</option>" +
        "             <option value='4'>4</option>" +
        "             <option value='5'>5</option>" +
        "             <option value='6'>6</option>" +
        "             <option value='7'>7</option>" +
        "             <option value='8'>8</option>" +
        "             <option value='9'>9</option>" +
        "             <option value='10'>10</option>" +
        "             <option value='11'>11</option>" +
        "             <option value='12'>12</option>" +
        "             <option value='13'>13</option>" +
        "             <option value='14'>14</option>" +
        "             <option value='15'>15</option>" +
        "             <option value='16'>16</option>" +
        "             <option value='17'>17</option>" +
        "             <option value='18'>18</option>" +
        "             <option value='19'>19</option>" +
        "             <option value='20'>20</option>" +
        "             <option value='21'>21</option>" +
        "             <option value='22'>22</option>" +
        "             <option value='23'>23</option>" +
        "             <option value='24'>24</option>" +
        "             <option value='25'>25</option>" +
        "             <option value='26'>26</option>" +
        "             <option value='27'>27</option>" +
        "             <option value='28'>28</option>" +
        "             <option value='29'>29</option>" +
        "             <option value='30'>30</option>" +
        "             <option value='31'>31</option>" +
        "           </select>" +
        "         </div>" +
        "         <div class='date-sperator'>/</div>" +
        "         <div class='form-select'>" +
        "           <select name='post[offspring_attributes]["+i+"][birthdate_year]' style='color: rgb(150, 164, 184);'>" +
        "             <option value>Year</option>" +
                      yearDropdownOptions() +
        "           </select>" +
        "         </div>" +
        "       </div>" +
        "     </div>" +
        "   </div>" +
        "  </div>" +
        "  <div class='field'>" +
        "    <label>Biological Sex</label>" +
        "    <div class='form-select'>" +
        "      <select name='post[offspring_attributes]["+i+"][gender]'>" +
        "        <option value></option>" +
        "        <option value='male'>Male</option>" +
        "        <option value='female'>Female</option>" +
        "      </select>" +
        "    </div>" +
        "  </div>" +
        "  <a class='button dsr-pink remove-new-offspring'>" +
        "    <icon class='fas fa-minus'></icon>" +
        "    Remove Offspring" +
        "  </a>" +
        "  </div>" +
        "</div>"
    );

    $(".remove-new-offspring").click((obj) => {
      obj.stopPropagation();
      $(obj.currentTarget)
        .parent(".offspring_row")
        .remove();
      return false;
    });

    return false;
  });
});
